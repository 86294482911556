import http from "../axios/http"

//获取字段
export let getList = (params) => {
    return http.get("/crm/custom_index", { params })
}
//增加字段
export let postAdd = (params) => {
    return http.post("/crm/custom_insert", params)
}
//更新字段
export let postUpdate = (params) => {
    return http.post("/crm/custom_update", params)
}

<template>
  <div class="Field">
    <a-input-search
      placeholder="搜索字段"
      enter-button="搜索"
      style="width: 420px"
      @search="serchField"
    >
      <template #addonBefore>
        <a-select v-model:value="value" style="width: 120px">
          <a-select-option value="1">名称</a-select-option>
        </a-select>
      </template>
    </a-input-search>
    <a-button type="primary" style="margin-left: 30px" @click="handleAdd"
      >添加</a-button
    >
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 20px"
    >
      <template #display="{ record }">
        <a-switch v-model:checked="record.display"></a-switch>
      </template>
      <template #req="{ record }">
        <a-switch v-model:checked="record.req"></a-switch>
      </template>
      <template #screen="{ record }">
        {{ record.screen }}
      </template>
      <template #type="{ record }">
        {{
          record.type === "text"
            ? "文本框"
            : record.type === "select"
            ? "多选框"
            : record.type === "radio"
            ? "单选框"
            : "时间型"
        }}
      </template>
      <template #style="{ record }">
        {{ record.style === "0" ? "系统" : "自定义" }}
      </template>
      <template #search="{ record }">
        {{
          record.search === "1"
            ? "模糊搜索"
            : record.search === "2"
            ? "精准搜索"
            : "否"
        }}
      </template>
      <template #operation="{ record }">
        <a-button type="primary" size="small" @click="handleEdit(record)"
          >编辑</a-button
        >
        <a-popconfirm
          title="Are you sure delete this task?"
          ok-text="Yes"
          cancel-text="No"
          v-if="!record.req"
        >
          <a-button size="small">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :title="id ? '编辑字段' : '添加字段'"
      centered
      :maskClosable="false"
      :width="800"
      class="field"
      @ok="okAdd"
      @cancel="formRest"
    >
      <a-form class="form" :model="formState">
        <a-form-item label="字段中文名称" required>
          <a-input placeholder="请输入" v-model:value="formState.label" />
        </a-form-item>
        <a-form-item label="字段英文名称" required>
          <a-input placeholder="请输入" v-model:value="formState.fieldName" />
        </a-form-item>
        <a-form-item label="控件类型" required>
          <a-select placeholder="请选择" v-model:value="formState.type">
            <a-select-option value="text">文本框</a-select-option>
            <a-select-option value="radio">单选框</a-select-option>
            <a-select-option value="select">多选框</a-select-option>
            <a-select-option value="date">时间型</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="可选择值" required v-if="formState.type === 'select'">
          <a-input placeholder="多个值用英文,隔开" v-model:value="formState.val" />
        </a-form-item>
        <a-form-item label="布局顺序">
          <a-input placeholder="请输入" v-model:value="formState.sort" />
        </a-form-item>
        <!-- <a-form-item label="字段长度">
          <a-input v-model:value="formState.type" />
        </a-form-item> -->
        <a-form-item label="列表是否显示">
          <a-switch v-model:checked="formState.display"></a-switch>
        </a-form-item>
        <a-form-item label="搜索">
          <a-radio-group name="radioGroup" v-model:value="formState.search">
            <a-radio value="1">模糊搜索</a-radio>
            <a-radio value="2">精准搜索</a-radio>
            <a-radio value="3">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否必填">
          <a-switch v-model:checked="formState.req"></a-switch>
        </a-form-item>
        <a-form-item label="是否弹屏">
          <a-switch v-model:checked="formState.screen"></a-switch>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getList, postAdd, postUpdate } from "../../service/field";
import { onMounted, reactive, ref, toRefs } from "vue";
import { message } from "ant-design-vue";
const columns = [
  {
    title: "布局排序",
    dataIndex: "sort",
  },
  {
    title: "字段中文名",
    dataIndex: "label",
  },
  {
    title: "字段英文名",
    dataIndex: "fieldName",
  },
  // {
  //   title: "字段长度",
  //   dataIndex: "length",
  // },
  {
    title: "列表是否显示",
    dataIndex: "display",
    slots: {
      customRender: "display",
    },
  },
  {
    title: "是否必填",
    dataIndex: "req",
    slots: {
      customRender: "req",
    },
  },

  {
    title: "字段类型",
    dataIndex: "type",
    slots: {
      customRender: "type",
    },
  },
  {
    title: "所属类型",
    dataIndex: "style",
    slots: {
      customRender: "style",
    },
  },
  {
    title: "搜索",
    dataIndex: "search",
    slots: {
      customRender: "search",
    },
  },
  {
    title: "是否弹屏",
    dataIndex: "screen",
    slots: {
      customRender: "screen",
    },
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "operation",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "Field",
  setup() {
    const state = reactive({
      visible: false,
      data: [],
      value: "1",
      id: undefined,
      arr: [
        "type",
        "label",
        "fieldName",
        "sort",
        "display",
        "req",
        "screen",
        "search",
        "val",
      ],
    });
    const serchField = () =>{
      console.log(123);
    }
    const formState = reactive({
      type: undefined,
      label: undefined,
      fieldName: undefined,
      sort: undefined,
      display: true,
      req: true,
      screen: false,
      search: "1",
      val: undefined,
    });
    const formRest = () => {
      formState.type = undefined;
      formState.label = undefined;
      formState.fieldName = undefined;
      formState.sort = undefined;
      formState.display = true;
      formState.req = true;
      formState.screen = undefined;
      formState.search = "1";
      formState.val = undefined;
      state.id = undefined;
    };
    onMounted(() => {
      getListFun();
    });
    const getListFun = async () => {
      const res = await getList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.data = res.data.data;
      state.data.forEach((item) => {
        item.display === "1" ? (item.display = true) : (item.display = false);
        item.req === "1" ? (item.req = true) : (item.req = false);
        item.screen === "1" ? (item.screen = true) : (item.screen = false);
      });
    };
    const handleAdd = () => {
      state.visible = true;
    };
    const handleEdit = (record) => {
      state.visible = true;
      state.id = record.id;
      console.log(record);
      for (let key in record) {
        if (state.arr.includes(key)) {
          formState[key] = record[key];
        }
      }
    };
    const okAdd = async () => {
      const params = {};
      for (let key in formState) {
        if (typeof formState[key] === "boolean") {
          formState[key] ? (params[key] = "1") : (params[key] = "0");
        } else {
          params[key] = formState[key];
        }
      }
      console.log(params);
      if (state.id) {
        params.id = state.id;
        const res = await postUpdate({
          access_token: sessionStorage.getItem("token"),
          ...params,
        }); 
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      } else {
        const res = await postAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        console.log(res);
        if (res.data.status == 100000) {
          message.success(res.data.msg);
          state.visible = false;
          formRest();
          getListFun();
        } else {
          message.error(res.data.msg);
        }
      }
    };
    return {
      value: ref("1"),
      ...toRefs(state),
      columns,
      handleAdd,
      handleEdit,
      formState,
      okAdd,
      formRest,
      serchField
    };
  },
};
</script>
<style >
.field .form .ant-form-item-label {
  min-width: 105px;
}
</style>
<style scoped>
.Field {
  height: 100%;
  background-color: #fff;
  padding: 15px;
}
.ant-btn-primary:nth-child(1) {
  margin-right: 6px;
}
.form {
  display: flex;
  flex-wrap: wrap;
}
.form .ant-form-item {
  width: 360px;
}
.form .ant-form-item:nth-child(2n + 1) {
  margin-right: 30px;
}
</style>